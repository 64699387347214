import React, { useState } from 'react';
import styles from './Deposit.module.scss';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask'; // Импортируем InputMask
import secureLocalStorage from 'react-secure-storage';
import axios from 'axios';

export default function Deposit({ theme,account,accounts,setSnackType,setSnackMessage,handleClick }) {
    const { t } = useTranslation();
    const [withdrawalType, setWithdrawalType] = useState(0);
    const [amount, setAmount] = useState('')
    const [fullname, setFullName] = useState('')
    const [address, setAddress] = useState('')
    // Добавляем состояние для поля ввода кредитной карты
    const [creditCard, setCreditCard] = useState('');
    const {id} = useParams()

    const Replenish = () => {
        if(!amount || +amount  < 0){
            setSnackType('error')
            handleClick()
            setSnackMessage(t('Amount Error'))
            return
        }
        axios.post('https://trade.pro-finansy.pro/api/transaction/link', {
            amount: amount,
            login: secureLocalStorage.getItem('user').Login
        })
            .then((res) => {
                console.log(res);
                if (res) {
                    window.location.href = res.data.link.url;
                }
            })
    }

    return (
        <div className={styles.withdrawal}>
            <div className={styles.withdrawal_title}>
                <Link to='/cabinet'>
                    <svg xmlns="http://www.w3.org/2000/svg" width={'1.6vh'} height={'1.6vh'} viewBox="0 0 16 16" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M1.67939 5.21532C1.33325 5.84768 1.33325 6.61049 1.33325 8.13611V9.15013C1.33325 11.7507 1.33325 13.051 2.1143 13.8589C2.89535 14.6668 4.15243 14.6668 6.66658 14.6668H9.33325C11.8474 14.6668 13.1045 14.6668 13.8855 13.8589C14.6666 13.051 14.6666 11.7507 14.6666 9.15013V8.13611C14.6666 6.61049 14.6666 5.84768 14.3205 5.21532C13.9743 4.58297 13.342 4.1905 12.0772 3.40558L10.7439 2.57808C9.40699 1.74836 8.73854 1.3335 7.99992 1.3335C7.26129 1.3335 6.59284 1.74836 5.25594 2.57808L3.92261 3.40558C2.65788 4.19051 2.02552 4.58297 1.67939 5.21532ZM5.99992 11.5002C5.72378 11.5002 5.49992 11.724 5.49992 12.0002C5.49992 12.2763 5.72378 12.5002 5.99992 12.5002H9.99992C10.2761 12.5002 10.4999 12.2763 10.4999 12.0002C10.4999 11.724 10.2761 11.5002 9.99992 11.5002H5.99992Z" fill="#51525C" />
                    </svg>
                    {t('Home')}
                </Link>
                <span>/</span>
                <Link to={`/cabinet/accounts/${id?id:account?.Id}`}>
                    {id?accounts?.find((val)=>val?.Id == id)?.AccountTitle:account?.AccountTitle}
                </Link>
                <span>/</span>
                <span>{t('Deposit')}</span>
            </div>
            <div className={styles.withdrawal_body}>
                <div className={styles.withdrawal_form}>
                    <div className={styles.amount}>
                        <label>{t('Deposit amount')}</label>
                        <input onChange={e => setAmount(e.target.value)} value={amount} placeholder={t('Deposit amount')} type='number' />
                    </div>
                    <div className={styles.amount}>
                        <label>{t('Full name')}</label>
                        <input onChange={e=>setFullName(e.target.value)} value={fullname} placeholder={t('Full name')} type='text' />
                    </div>
                    <div className={styles.buttons}>
                        <Link to='/cabinet'>{t('Cancel')}</Link>
                        <button onClick={()=>Replenish()}>{t('Continue')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
